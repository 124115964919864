<template>
	<div>
		<v-row>
			<v-col cols="12">
				<transport-line @rowSelected="rowSelectedShipping"></transport-line>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<transport :TransportLine="TransportLine"></transport>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import Transport from './Transport.vue';
	import TransportLine from "./TransportLine.vue";
	import TypeTransportLine from "./TypeTransportLine.vue";
	export default {
		components: {
			Transport,
			TransportLine,
			TypeTransportLine
		},

		data() {
			return {
				TransportLine: null
			}
		},

		methods: {
			rowSelectedShipping(row) {
				this.TransportLine = row;
			},
		},
	}
</script>
