<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			add
			edit
			remove
			height="150"
			title="Linea Transporte"
			@save="save($event)"
			@rowSelected="rowSelected($event)"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12">
							<s-text 
								label="Nombre"
								v-model="props.item.TrlName"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12">
							<s-select-definition 
								:def="1299" 
								label="Tipo Linea Transporte" 
								v-model="props.item.TypeTypeTransportLine"
							/>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12">
							<s-select-definition 
								:def="1295" 
								label="Estado" 
								v-model="props.item.TrlStatus"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

		</s-crud>
	</div>
</template>

<script>
	import _sExtTransporteLineService from "@/services/ExternalTrade/ExtTransporteLineService.js";

	export default {
		data() {
			return { 
				filter: {},
				config: {
					model: {
						TrlID: "ID"
					},
					service: _sExtTransporteLineService,
					headers: [
						{text: "ID", value: "TrlID"},
						{text: "Nombre", value: "TrlName"},
						{text: "Tipo Linea Transporte", value: "TypeTypeTransportLineName"},
						{text: "Estado", value: "TrlStatusName"},
					]
				},
			}
		},

		methods: {
			rowSelected(rows)
			{
				this.$emit('rowSelected', rows[0])
			},

			save(item) {
				item.save();
			}
		},

		created () {
			this.filter = { TtlID: 0 };
		},
	}
</script>