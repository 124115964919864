<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			add
			edit
			remove
			title="Transporte"
			height="210"
			@save="save($event)"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="6" md="6" sm="12">
							<s-text 
								label="Nombre"
								v-model="props.item.TrpName"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="6" md="6" sm="12">
							<s-select-definition 
								:def="1295" 
								label="Estado" 
								v-model="props.item.TrpStatus"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

		</s-crud>
	</div>
</template>

<script>
	import _sExtShipService from "@/services/ExternalTrade/ExtShipService.js";

	export default {
		props: {
			TransportLine: null,
		},

		data() {
			return { 
				filter: {},
				config: {
					model: {
						TrpID: "ID"
					},
					service: _sExtShipService,
					headers: [
						{text: "ID", value: "TrpID"},
						{text: "Nombre", value: "TrpName"},
						{text: "Estado", value: "TrpStatusName"},
					]
				}
			}
		},

		watch: {
			TransportLine() {
				this.Initialize();
			}
		},

		methods: {
			Initialize() {
				if(this.TransportLine != null){
					this.filter = { TrlID: this.TransportLine.TrlID};
				}
			},

			save(item) {
				item.TrlID = this.TransportLine.TrlID;
				item.save();
			}
		},

		created () {
			if(this.TransportLine != null){
				this.filter = { TrlID: this.TransportLine.TrlID };
			}
			
		},
	}
</script>