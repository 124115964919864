<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			add
			edit
			remove
			height="150"
			title="Lineas de Transporte"
			@save="save($event)"
			@rowSelected="rowSelected($event)"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="6" md="6" sm="12">
							<s-text 
								label="Nombre"
								v-model="props.item.TtlName"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="6" md="6" sm="12">
							<s-select-definition 
								:def="1298" 
								label="Estado" 
								v-model="props.item.TtlStatus"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<!-- <template v-slot:ShiStatus="{row}">
				<v-chip x-small :color="">

				</v-chip>
			</template> -->

		</s-crud>
	</div>
</template>

<script>
	import _sExtTypeTransportLineService from "@/services/ExternalTrade/ExtTypeTransportLineService.js";

	export default {
		data() {
			return { 
				filter: {},
				config: {
					model: {
						TtlID: "ID"
					},
					service: _sExtTypeTransportLineService,
					headers: [
						{text: "ID", value: "TtlID"},
						{text: "Nombre", value: "TtlName"},
						{text: "Estado", value: "TtlStatusName"},
					]
				}
			}
		},

		methods: {
			rowSelected(rows)
			{
				this.$emit('rowSelected', rows[0])
			},

			save(item) {
				item.save();
			}
		},
	}
</script>