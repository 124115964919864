import Service from '../Service';

const resource = "ship/";

export default {

    pagination(filter, requestID) {
        return Service.post(resource + "pagination", filter, {
            params: { requestID: requestID }
        });
    },

    save(shp, requestID) {
        return Service.post(resource + "save", shp, {
            params: { requestID: requestID },
        });
    },

}