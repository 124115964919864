import Service from '../Service';

const resource = "typetransportline/";

export default {

    pagination(filter, requestID) {
        return Service.post(resource + "pagination", filter, {
            params: { requestID: requestID }
        });
    },

    save(ttl, requestID) {
        return Service.post(resource + "save", ttl, {
            params: { requestID: requestID },
        });
    },

}