import Service from '../Service';

const resource = "transportline/";

export default {

    pagination(filter, requestID) {
        console.log('filter ', filter);
        return Service.post(resource + "pagination", filter, {
            params: { requestID: requestID }
        });
    },

    save(trl, requestID) {
        return Service.post(resource + "save", trl, {
            params: { requestID: requestID },
        });
    },

}